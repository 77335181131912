const moment = require('moment');
import Swiper from 'swiper/bundle';
$(function () {

	if ($("#topActivities .swiper-slide").length) {
		var swiperTopActivities = new Swiper('#topActivities', {
			slidesPerView: 'auto',
			spaceBetween: 18,
			centeredSlides: true,
			loop: true,
			loopFillGroupWithBlank: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}
	var swiperTopAboutSlideleft = new Swiper('#topAboutSlideleft', {
		slidesPerView: 'auto',
		direction: 'vertical',
		autoHeight: true,
		spaceBetween: 0,
		centeredSlides: true,
		loop: true,
		loopFillGroupWithBlank: true,
		freeMode: true,
		speed: 10000,
		autoplay: {
			delay: 1,
		}
	});
	var swiperTopAboutSlideright = new Swiper('#topAboutSlideright', {
		slidesPerView: 'auto',
		direction: 'vertical',
		autoHeight: true,
		spaceBetween: 0,
		centeredSlides: true,
		loop: true,
		loopFillGroupWithBlank: true,
		freeMode: true,
		speed: 10000,
		autoplay: {
			delay: 1,
			reverseDirection: true
		}
	});
	var swiperTopAboutSlidesp = new Swiper('#topAboutSlidesp', {
		slidesPerView: 'auto',
		autoHeight: true,
		spaceBetween: 0,
		centeredSlides: true,
		loop: true,
		loopFillGroupWithBlank: true,
		freeMode: true,
		speed: 10000,
		autoplay: {
			delay: 1
		}
	});
	var swiperTopMainImage = new Swiper('#topMainImage', {
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		loop: true,
		effect: 'fade',
		speed: 3000,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false
		}
	});
	var swiperTopMainImage = new Swiper('#topMainImageSP', {
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		loop: true,
		effect: 'fade',
		speed: 3000,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false
		}
	});

	// $(".toppage__load").addClass("is-active")
	$(".toppage__load__logo").addClass("is-fade")
	setTimeout(() => {
		$(".toppage__load").addClass("is-active")
		setTimeout(() => {
			$(".toppage__load").remove()
		}, 1000);
	}, 2500);

});
$(window).on('load', function () {
})
