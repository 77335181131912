
import Swiper from 'swiper/bundle';
$(function () {
	var swiperStayAboutPhoto = new Swiper('#stayAboutPhoto', {
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
	var swiperStayGuestroomPhoto = new Swiper('#stayGuestroomPhoto', {
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		on: {
			slideChange: function () {
				console.log('現在のスライド番号： ' + (this.realIndex + 1));
				stayGuestroomData(this.realIndex);
			},
		}
	});

	var swiperStayGuestroomGroupPhoto = new Swiper('#stayGuestroomGroupPhoto', {
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	});

	var swiperStayGallery = new Swiper('#stayGallery', {
		slidesPerColumn: 3,
		slidesPerView: 'auto',
		loop: false,
		centeredSlides: false,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			769: {
				loop: false,
				slidesPerColumn: 2,
				centeredSlides: false,
			},
		}
	});
	//
	var swiperActivityRecommend = new Swiper('#activityRecommend', {
		slidesPerView: 'auto',
		spaceBetween: 0,
		loop: true,
		loopAdditionalSlides: 3,
		centeredSlides: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			769: {
				centeredSlides: false,
			},
		}
	});

	//
	var swiperFoodDining = new Swiper('#food', {
		spaceBetween: 0,
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true
		},
	});

	///

	function stayGuestroomData($index) {
		var beforeHeight = $(".about__data.is-selected").height();
		var index = $index;
		$(".about__data").removeClass("is-selected");
		$(".about__data")
			.eq(index)
			.addClass("is-selected");
		var afterHeight = $(".about__data.is-selected").height();
		var changeHeight = afterHeight - beforeHeight;
		console.log(beforeHeight, afterHeight, changeHeight)
		$(".about__box").css({
			height: afterHeight
		})
	}


	//
	$(document).on('click', '#navOpen', function () {
		$('#nav').toggleClass('is-active');
	})

	$(document).on('click', '#navClose', function () {
		$('#nav').toggleClass('is-active');
	})

	//
	$(document).on('click', '#galleryList li a', function () {
		const $image = $(this).attr('href');
		$('#galleryPopupImage').attr('src', $image);
		$('#galleryOverlay').addClass('is-active');
		$('#galleryPopup').addClass('is-active');
		return false;
	})
	//
	$(document).on('click', '#galleryClose', function () {
		$('#galleryOverlay').removeClass('is-active');
		$('#galleryPopup').removeClass('is-active');
		setTimeout(() => {
			$('#galleryPopupImage').attr('src', '#');
		}, 500);
		return false;
	})

	//
	if ($('#activityNav').length) {
		let checked;
		$("#activityNav li,#activityFixednav li").on("click", function () {
			if (checked === $(this).data('filter')) {
				checked = '';
				$("#activityNav li").addClass("is-active");
				$("#activityFixednav li").addClass("is-active");
			} else {
				checked = $(this).data('filter');
				$("#activityNav li").removeClass("is-active");
				$("#activityFixednav li").removeClass("is-active");
				$('#activityNav .' + checked).addClass("is-active");
				$('#activityFixednav .' + checked).addClass("is-active");
			}
			console.log(checked)
			$("#activityList .item").each(function () {
				const season = $(this).data("season");
				const is_exist = $.inArray(checked, season);
				console.log(is_exist);
				if (is_exist != -1) {
					$(this).removeClass("hidden");
				} else {
					$(this).addClass("hidden");
				}
				if (checked === '') {
					$(this).removeClass("hidden");
				}
			});
			return false;
		});
	}
});

$(window).scroll(function () {
	if ($(window).scrollTop() > 375) {
		$('#header').addClass('is-scroll');
	} else {
		$('#header').removeClass('is-scroll');
	}
	if ($('#activityFixednav').length) {
		if ($(window).scrollTop() > $('#activityList').offset().top && $(window).scrollTop() < $('#booking').offset().top - $(window).height()) {
			$('#activityFixednav').addClass('is-scroll');
		} else {
			$('#activityFixednav').removeClass('is-scroll');
		}
	}
});
